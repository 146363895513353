import "./App.css";
import "./style.css";
import "./slider.css";
import $ from "jquery";
import "slick-carousel";
import Swiper from "swiper";
import "swiper/css";
import "bootstrap/dist/css/bootstrap.min.css";
import { lazy, useEffect } from "react";
import { CartProvider } from "./context/CartProvider";
// import Course1 from "./pages/profile/HowCanWeHelp/course";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Loadable } from "./components/Loader/Loadable";
import Auth from "./pages/auth/Auth";
import "bootstrap/dist/js/bootstrap.bundle";
// import { useEffect, useState } from "react";
import SuccessVerification from "./pages/auth/SuccessVerification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/auth/ForgotPassword";
import PasswordReset from "./pages/auth/PasswordReset";
import ConfirmPassword from "./pages/auth/ConfirmPassword";
import PasswordResetSuccess from "./pages/auth/PasswordResetSuccess";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { useAuthStateContext } from "./context/AuthProvider";

import ReeeetPassword from "./pages/auth/reSetPassword";

import BagdeShare from "./pages/auth/BadgePage";

import jwt_decode from "jwt-decode";


import { Nav } from "react-bootstrap";
import axios from "axios";
const HomePage = Loadable(lazy(() => import("./pages/homePage")));
const Writetous = Loadable(lazy(() => import("./pages/write_to_us")));
const Yourquestions = Loadable(lazy(() => import("./pages/your_question")));
const Whatsunique = Loadable(lazy(() => import("./pages/whats_unique")));
const Exploremore = Loadable(lazy(() => import("./pages/explore_more")));
const Notification = Loadable(lazy(() => import("./pages/notification")));

// const Terms = Loadable(lazy(() => import("./pages/terms")));

const Corporate = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/Corporate"))
);
const InPerson = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/InPersonCourse"))
);
const CourseDetail = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/course-detail/Detail"))
);
const Checkout = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/checkout"))
);
const Cart = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/cart/Cart"))
);
const VerifyEmail = Loadable(lazy(() => import("./pages/auth/VerifyEmail")));


const Verifedmail = Loadable(lazy(() => import("./pages/auth/Verifedmail")));
const ResetPass = Loadable(lazy(() => import("./pages/auth/ResetPass")));


const Courses = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/howcanwehelp"))
);
const MyProfile = Loadable(lazy(() => import("./pages/profile/MyProfile")));
const SelfPaceCourse2 = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/SelfPaceCourse2"))
);
const Course1 = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/course"))
);
const Payment = Loadable(
  lazy(() => import("./pages/profile/HowCanWeHelp/payment/Payment"))
);
 const NavExploremore = Loadable(lazy(() => import("./pages/nav_explore_more")));
 const BookSlot = Loadable(lazy(() => import("./pages/BookSlot")));


 const Terms = Loadable(lazy(() => import("./pages/terms")));
 const Disclaimer = Loadable(lazy(() => import("./pages/disclaimer")));
 const Policy = Loadable(lazy(() => import("./pages/policy")));


 const PaymentS = Loadable(lazy(() => import("./pages/payments")));
 const PaymentF = Loadable(lazy(() => import("./pages/paymentf")));





 

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,

    },
  },
});

function App() {
  $(".first-slider")
    .not(".slick-initialized")
    .slick({
      infinite: true,
      //  arrows: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,

      prevArrow: $(".prev"),
      nextArrow: $(".next"),
    });
  $(".our-clnt-slider").slick({
    // dots: true,
    infinite: true,
    autoplay: false,
    speed: 300,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  const swiper = new Swiper(".swiper", {
    // Optional parameters
    direction: "horizontal",

    autoplay: true,
    loop: true,
    autoHeight: false,
    centeredSlides: true,
    slidesPerView: 1,
    // Responsive breakpoints
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // And if we need scrollbar
    /*scrollbar: {
      el: '.swiper-scrollbar',
    },*/
  });

  // ===== testimonail sldier ====
  $(document).ready(function () {
    $(".center-slider").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      arrows: false,
      dots: false,
      speed: 300,
      centerPadding: "-20px",
      infinite: true,
      autoplaySpeed: 5000,
      autoplay: true,
    });
  });

  const { state } = useAuthStateContext();
  const token1 = state?.authUser?.token;

  const decoded = token1 && jwt_decode(token1);

  useEffect(() => {

    // Function to check token expiration

      const tokenExpiration =  decoded;
      if (tokenExpiration) {
        const now = new Date().getTime() / 1000; // Convert to seconds
        if (now > tokenExpiration?.exp) {
          // Token has expired
      
          localStorage.removeItem('accessToken');
          localStorage.removeItem('course_id')
          localStorage.removeItem('_users')
        }

      }

   }, []);
  
  const newLocal = "/sharebadge/course/:id";
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <ToastContainer autoClose={10000}/>
        <CartProvider>
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/write_to_us" element={<Writetous />} />
              <Route path="/your_question" element={<Yourquestions />} />
              <Route path="/whatsunique" element={<Whatsunique />} />
              <Route path="/explore_more/:id" element={<Exploremore />} />
              <Route path="/explore_more" element={<NavExploremore />} />
              <Route path="/how_can_we_help" element={<Courses />} />
              <Route path="/course/:id" element={<Course1 />} />
              <Route path="/corporate-course" element={<Corporate />} />
              <Route path="/in-person-course" element={<InPerson />} />
              <Route path="/course-detail/:id" element={<CourseDetail />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/sharebadge/course/:id" element={<BagdeShare />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/self-pace-course" element={<SelfPaceCourse2 />} />
              <Route path="/checkout/payment" element={<Payment />} />

              <Route path="/policy" element={<Policy />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/payment-success" element={<PaymentS />} />
              <Route path="/payment-failure" element={<PaymentF />} />
              <Route path="/verified_email" element={<Verifedmail />} />
              <Route path="/reset-password" element={<ResetPass />} />
              <Route path="/notification" element={<Notification />} />

              

              

              <Route
                path="/verification-success"
                element={<SuccessVerification />}
              />
              <Route
                path="/auth/forgot-password"
                element={<ForgotPassword />}
              />
              <Route path="/auth/password-reset" element={<PasswordReset />} />


              <Route path="/auth/reSetPassword" element={<ReeeetPassword />} />

             

              <Route path="/auth/new-password" element={<ConfirmPassword />} />
              <Route
                path="/auth/password-reset-success"
                element={<PasswordResetSuccess />}
              />
              <Route element={<ProtectedRoute isAllowed={true} />}>
                <Route path="profile" element={<MyProfile />} />
                <Route path="book-slot/:id" element={<BookSlot />} />
                {/* <Route path="home" element={<Home />} />
                <Route path="dashboard" element={<Dashboard />} /> */}
              </Route>
              {/* <Route path="/:category" element={<Accordion />} /> */}
            </Routes>
          </Router>
        </CartProvider>
      </QueryClientProvider>
    </>
  );
}
export default App;
