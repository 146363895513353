import React from "react";
import CarouselLeft from "../CarouselLeft";
import tick from "../../assets/tick.png";
import { Divider, Typography } from "@mui/material";
import { ColorButton } from "./Login";
import { useNavigate } from "react-router-dom";
import successImage from "../../assets/Thumbs Up.jpg"
const PasswordResetSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="container-fluid login-wrapp">
      <div className="row">
        <CarouselLeft image={successImage}/>
        <div
          className="col-md-6 login-signup-wrapp d-flex align-items-center justify-content-center">
            <div>
              <div className="green-tick-img-block mb-2 tck-mark-block">
              <img src={tick} alt="" />
              </div>
              <div class="form-title mb-4"><span>Password Reset</span></div>
              <div>Your password is reset successfully</div>
              <div class="d-flex mt-3">
                <button className="btn-v" type="button" onClick={() => navigate("/auth")}>Go to Login</button>
              </div>
            </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              alignItems: "start",
            }}
          >
            <img src={tick} alt="" />
            <Typography sx={{ fontSize: "42px", fontWeight: "bold" }}>
              Password Reset Successfully
            </Typography>
            <Divider
              sx={{ background: "#565C83", width: "166px", height: "4px" }}
            />
            <Typography>
             
            </Typography>
            <ColorButton onClick={() => navigate("/auth")}>
              Go to Login
            </ColorButton>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
