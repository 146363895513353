import axios from "axios";

import { CONFIG } from "../config";
import { getToken } from "../utils/getTokens";

const onRequest = (config) => {
  const token = getToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = async (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      const storedToken = getToken();

      try {
        const rs = await axios.post(`${CONFIG.BASE_URI}auth/refresh/`, {
          refresh: storedToken.refresh,
        });

        const { access } = rs.data;

        const updatedUsr = {
          ...storedToken,
          access,
        };

        localStorage.setItem("_users", JSON.stringify(updatedUsr));

        return;
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
