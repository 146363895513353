import React, { useState } from "react";
import CarouselLeft from "../CarouselLeft";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useResetPassword } from "../../api/auth";
import { toast } from "react-toastify";
import { ColorButton } from "./Login";
import eyepassword from "../../assets/eye-open-svgrepo-com.svg";
import email from "../../assets/noun-mail-1707180.svg";
import successImage from "../../assets/login-signup-1.jpg"

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(false);
  const validationSchema = z.object({
    email: z.string().min(1, { message: "Email must not be empty" }),
    newpassword: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" }),
    confirmpassword: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" })
      .refine((data) => data.newpassword === data.confirmpassword, {
        path: ["confirmPassword"],
        message: "Password don't match",
      }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(validationSchema) });

  const { mutateAsync } = useResetPassword();
  const onSubmit = async (formData) => {
    setLoading(true);
    const payload = {
      email: formData?.email,
      new_password: formData?.newpassword,
      confirm_password: formData?.confirmpassword,
    };
    try {
      const res = await mutateAsync(payload);
      if (res?.data?.status === "success") {
        toast.success(res?.data?.message);
        navigate("/auth/password-reset-success");
      } else if (res?.data?.status === "error") {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message);
    }
  };
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row">
        <CarouselLeft image={successImage} />
          <div className="col-md-6 login-signup-wrapp">
            <form autoComplete="off"
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: "16px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Typography sx={{ fontSize: "48px", fontWeight: "bold" }}>
                Forgot Password
              </Typography>
              <Divider
                sx={{ background: "#565C83", width: "166px", height: "4px" }}
              />
              <div className="bye">
                <img className="person" src={email} alt="#" />

                <Typography className="person-text">Email</Typography>

                <input
                  type="email"
                  className="Person-text1"
                  placeholder="Enter your email"
                  name="email"
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-danger font-italic mt-2">
                    {errors.email?.message}
                  </p>
                )}
              </div>
              <div className="bye">
                {inputType === "password" ? (
                  <img
                    className="person"
                    src={eyepassword}
                    alt="#"
                    onClick={() => setInputType("text")}
                  />
                ) : (
                  <img
                    className="person"
                    src={eyepassword}
                    alt="#"
                    onClick={() => setInputType("password")}
                  />
                )}
                <Typography className="person-text">New Password</Typography>

                <input
                  type={inputType}
                  className="Person-text1"
                  placeholder="Type your password"
                  name="newpassword"
                  {...register("newpassword")}
                />
                {errors.newpassword && (
                  <p className="text-danger font-italic mt-2">
                    {errors.newpassword?.message}
                  </p>
                )}
              </div>
              <div className="bye">
                {inputType === "password" ? (
                  <img
                    className="person"
                    src={eyepassword}
                    alt="#"
                    onClick={() => setInputType("text")}
                  />
                ) : (
                  <img
                    className="person"
                    src={eyepassword}
                    alt="#"
                    onClick={() => setInputType("password")}
                  />
                )}
                <Typography className="person-text">
                  Confirm Password
                </Typography>

                <input
                  type={inputType}
                  className="Person-text1"
                  placeholder="Type your password"
                  name="confirmpassword"
                  {...register("confirmpassword")}
                />
                {errors.confirmpassword && (
                  <p className="text-danger font-italic mt-2">
                    {errors.confirmpassword?.message}
                  </p>
                )}
              </div>
              <ColorButton key="submit" type="submit">
                Save
              </ColorButton>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
