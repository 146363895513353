import { createContext, useReducer, useContext } from "react";

const getAuthUser = () => {
  let users = null;
  try {
    const item = localStorage.getItem("_users");
    users = item ? JSON.parse(item) : {};
  } catch (error) {
    // eslint-disable-next-line no-console
    users = {};
  }
  return users;
};

const initialState = {
  authUser: getAuthUser(),
};

const AuthStateContext = createContext({});
const AuthStateUpdaterContext = createContext({});

const stateReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      const stringiFiedUser = JSON.stringify(action.payload);
      const accessToken = action?.payload?.token ?? "";
      localStorage.setItem("_users", stringiFiedUser);
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case "LOGOUT_USER": {
      if(localStorage.getItem("rememberMe")){
        localStorage.removeItem('cartItem')
        localStorage.removeItem('_users')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('cartItem')
      }else{
        localStorage.clear();
      }
     
      return {
        ...state,
        authUser: {},
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <AuthStateContext.Provider value={{ state }}>
      <AuthStateUpdaterContext.Provider value={{ dispatch }}>
        {children}
      </AuthStateUpdaterContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthStateContext = () => {
  const context = useContext(AuthStateContext);

  if (context) {
    return context;
  }

  throw new Error(`useAuthStateContext must be used within a AuthProvider`);
};

const useAuthStateUpdaterContext = () => {
  const context = useContext(AuthStateUpdaterContext);

  if (context) {
    return context;
  }

  throw new Error(
    `useAuthStateUpdaterContext must be used within a AuthProvider`
  );
};

export { AuthProvider, useAuthStateContext, useAuthStateUpdaterContext };
