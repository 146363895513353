import React from "react";
import Carousel from "react-bootstrap/Carousel";
import loginsignup1 from "../../assets/login-signup-1.jpg";
import whitelogologin from "../../assets/logo-white-login.svg";
import smiley from "../../assets/smiley.png";
import { ColorButton } from "./Login";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CarouselLeft from "../CarouselLeft";
import successImage from "../../assets/Thumbs Up.jpg"
const SuccessVerification = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row">
          <CarouselLeft image={successImage}/>
          <div className="col-md-6 login-signup-wrapp d-flex align-items-center justify-content-center">
            {/* <div
              className="login-contentpage container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                alignItems: "start",
              }}
            >
              <img src={smiley} alt="" />
              <Typography sx={{ fontSize: "42px", fontWeight: "bold" }}>
                Registered Successfully
              </Typography>
              <Divider
                sx={{ background: "#565C83", width: "166px", height: "4px" }}
              />
              <Typography>
                Proin consequat metus vitae neque aliquam, a ue aliquam.
              </Typography>
              <ColorButton onClick={() => navigate("/auth")}>
                Continue
              </ColorButton>
            </div> */}
            <div className="psword-reset">
            <div className="green-tick-img-block">
                    <img src="../img/smily.svg" alt="" />
                  </div>
              <div className="form-title"><span>Registered Successfully</span></div>
              <div className="green-tick-block">
                  
                  <div>
                    
                  </div>
              </div>
              <div className="d-flex mt--4">
                <button type="button" className="btn-v" onClick={() => navigate("/auth")}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessVerification;
