import CircularProgress from '@mui/material/CircularProgress';
export const Loader=(()=>{
    
   return(
    <div className='' style={{display:"flex",justifyContent:"center" ,alignItems:"center",height:"100vh" ,width:"100vw"}}>
    <div style={{display:'flex',alignItems:"center"}}><CircularProgress/> <div style={{marginLeft:16, fontSize:20}}>Loading...</div></div>

    
    </div>
   )

})