export const apiRoutes = {
  userRegistration: "/user-registeration",
  resendEmail: "/resend_verify_link",
  userLogin: "/login",
  sociallogin: "/sociallogin",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  profile: "/profile",
  myCourse: "/purchased-courses",
  purchaseHistory: "/purchase-history",
  changePassword: "/change-password",
  updateProfile: "/update-profile",
  catogeryList: "/category-list",
  notification: "/get_notification_trigger",
  categoryDetails: "/category-detail?category_name=",
  courseList: "/courses-list",
  courseDetail: "/course-detail?course_id=",
  newNotification:"/get_notification",

  getCheckcourse: "/checkCourse",
  moduleVideos: "/module-videos?module_id=",
  ShowCart: "/show-cart",
  AddToCart: "/add-to-cart",
  DeleteItem: "/cart-item-delete",
  checkout: "/checkout",
  addBilling: "/add-billing",
  paynow: "pay-amount-of-courses",
  blogDetails:"/blog-detail?blog_id=",
  blogCategory:"/blogs-list?blog_category=",
  webSiteData:"/site-data?webtype=",
  feedBack:"/feedback",
  donnloadIntro:'download-intro-attachment',
  shareBadge:'share-badge',
  getCertificate:'get-certificate',
  moduleStatus:'module-status',
  uploadAssignment:'upload-assignment',
  uploadProfile:'upload-profile-image',
  removeAllFromCart:'delete-cart',
  myCertificate:'my-certificates',
  getCountry:'country',
  getState:'state',
  getCity:'city',
  offeringData:'offering-data',


};
