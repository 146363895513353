import { usePost } from "../utils/react-query";

import { pathToUrl } from "../utils/router";

import { apiRoutes } from "./constant";
import { useFetch } from "../utils/react-query";


export const useRegisterUser = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.userRegistration),
    undefined,
    updater
  );
  return {
    ...response,
  };
};

export const useResendEmail = (updater) => {
  console.log(updater)
  const response = usePost(
    pathToUrl(apiRoutes.resendEmail),
    undefined,
    updater
  );
  return {
    ...response,
  };
};

export const useLogin = (updater) => {
  const response = usePost(pathToUrl(apiRoutes.userLogin), undefined, updater);
  return {
    ...response,
  };
};



export const useLoginSocial = (updater) => {
  const response = usePost(pathToUrl(apiRoutes.sociallogin), undefined, updater);
  return {
    ...response,
  };
};





export const useForgotPassword = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.forgotPassword),
    undefined,
    updater
  );
  return {
    ...response,
  };
};

export const useResetPassword = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.resetPassword),
    undefined,
    updater
  );
  return {
    ...response,
  };
};
export const useUpdateProfile = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.updateProfile),
    undefined,
    updater
  );
  return {
    ...response,
  };
};
export const useChangePassword = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.changePassword),
    undefined,
    updater
  );
  return {
    ...response,
  };
};
export const useGetCategoryDetail = (params) => {
	const res= useFetch(apiRoutes.categoryDetails, params, {
	});

	return {
		...res,
	};

};

export const useShowCart= (updater) => {
  // const response = usePost(
  //   pathToUrl(apiRoutes.ShowCart),
  //   undefined,
  //   updater
  // );
  // return {
  //   ...response,
  // };
  const res= useFetch(apiRoutes.ShowCart);

	return {
		...res,
	};
};
export const useAddToCart= (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.AddToCart),
    undefined,
    updater
  );
  return {
    ...response,
  };
};
export const useDeleteItemCart= (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.DeleteItem),
    undefined,
    updater
  );
  return {
    ...response,
  };
};
export const   useGetBlogDetails= (params) => {
	const {data,refetch}= useFetch(apiRoutes.blogDetails, params,  {
	});

	return {
		  data,
     
	};

};

export const   useGetBlogCategory= (params) => {
	const {data}= useFetch(apiRoutes.blogCategory, params,  {
	});

	return {
		  data,
     
	};

};
export const   useGetWebSiteData= (params) => {
	const {data}= useFetch(apiRoutes.webSiteData, params,  {
	});
	return {
		  data,    
	};
};


export const  useGetOfferingData= (params) => {
	const {data}= useFetch(apiRoutes.offeringData, params,  {
	});
	return {
		  data,    
	};
};



export const usePostFeedBack = (updater) => {
  const response = usePost(
    pathToUrl(apiRoutes.feedBack),
    undefined,
    updater
  );
  return {
    ...response,
  };
};

  export const useUploadProfile = () => {
    const res= usePost(apiRoutes.uploadProfile,undefined, undefined,{
      headers: {
        "Content-Type": "multipart/form-data",
    },
    });
  
    return {
      ...res,
      data:res?.data?.data??{}
    };
  
  };



