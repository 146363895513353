import styled from "@emotion/styled";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useCallback, useState, useRef, useEffect, useContext } from "react";
import email from "../../assets/noun-mail-1707180.svg";
import eyepassword from "../../assets/eye-open-svgrepo-com.svg";
import eyeclosedpassword from "../../assets/eye-closed.png";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useLogin, sociallogin, useAddToCart } from "../../api/auth";
import { toast } from "react-toastify";
import { CONFIG } from '../../config';

import ReCAPTCHA from "react-google-recaptcha";
import {
  LoginSocialFacebook,
  LoginSocialLinkedin,
  LoginSocialTwitter,
  IResolveParams,
} from "reactjs-social-login";
import facebookIcon from "../../assets/facebook icon.svg";
import linkedIn from "../../assets/linkedin icon.png";
import twitter from "../../assets/twitter icon.png";

import { useAuthStateUpdaterContext } from "../../context/AuthProvider";
import { FiAlignJustify } from "react-icons/fi";
import { CartContext } from "../../context/CartProvider";
import { getToken } from "../../utils/getTokens";
import axios, { Axios } from "axios";

export const ColorButton = styled(Button)(({ theme }) => ({
  color: "#000",
  backgroundColor: "#D3F433",
  padding: "16px 60px",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "#333499",
  },
  "&:active": {
    background: "#D3F433",
  },
}));

const Login = () => {
  const { dispatch } = useAuthStateUpdaterContext();
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [captch, setCaptch] = useState(false);
  const { cartId, refetch } = useContext(CartContext);
  const [captchaVerified, setCaptchaVerified] = useState(!CONFIG.CAPTCHA_SITE_KEY);
  const [captchaMsg, setCaptchMsg] = useState(null)
  const [rememberMe, setRememberMe] = useState(false);
  const { mutateAsync: addToCart, isLoading } = useAddToCart();
  const navigate = useNavigate();

  const REDIRECT_URI = "http://localhost:3000/auth";
  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);

  const captchaRef = useRef(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe && storedEmail && storedPassword) {
      setRememberMe(true);
    }
  }, []);

  const onLoginSuccess = ({ provider, data }) => {
    if (data?.accessToken) {
      toast.success(`Login with ${provider} successful`);
      navigate("/");
    } else {
      toast.error("Login Failed");
    }
  };

  function onHandleCaptcha(value) {
    if (value) {
      setCaptchaVerified(true);
    }
  }

  const handleCaptchaExpired = () => {
    setCaptchaVerified(false);
  };
  const handleRemember = (e) => {
    setRememberMe(e.target.checked)
  }
  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const [inputType, setInputType] = useState("password");
  const [loading, setLoading] = useState(false);
  const validationSchema = z.object({
    text: z.string().min(1, { message: "Email is required" }),
    password: z.string().min(8, { message: "Password must be at least 8 characters" }),
  });
  console.log("AUTHHH", window.location.pathname === "/auth");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(validationSchema) });

  const { mutateAsync } = useLogin();
  const onSubmit = async (formData) => {
    setLoading(true);
    const payload = {
      text: formData?.text,
      password: formData?.password,
    };
    try {
      if (captchaVerified) {
        const res = await mutateAsync(payload);
        if (res?.data?.status === "success") {
            const token = getToken();

          const authUser = res?.data?.data;
          toast.success(res?.data?.message);

          localStorage.setItem("cartItem", res?.data?.cart?.totalItemsInCart)
          dispatch({ type: "SET_USER", payload: authUser });

          if (rememberMe) {
            localStorage.setItem("email", formData?.text);
            localStorage.setItem("password", formData?.password);
            localStorage.setItem("rememberMe", rememberMe.toString());
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
          }
          if (localStorage.getItem('course_id')) {

            console.log("vvvvvv", res?.data?.data?.token);
        
            if (res?.data?.data?.token) {
              postData(res?.data?.data?.token)
            }
          } else {
            navigate("/");

          }
        } else if (res?.data?.status === "error") {
          setLoading(false);
          toast.error(res?.data?.message);
        }
      } else {
        setCaptchMsg("Captcha is Required !")
      }

    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message);
    }
  };
  const postData = async (token) => {
    let courseID = localStorage.getItem('course_id');

    const payloads={
      ...( cartId && {cart_id:cartId}),
      course_id: courseID,
    }
    try {
      const res = await axios.post(
        'https://admin.irisinnovate.com/api/add-to-cart',
        payloads, // Replace with your data
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (res?.data?.status === "success") {
        console.log("vvvvvv", localStorage.getItem('course_id'));

        toast.success(res.data.message);
        refetch()
        navigate('/cart')

      } else if (res?.data?.status === "error") {
        console.log("vvvvvv1", localStorage.getItem('course_id'));

        if (res.data.message && res?.data?.status === "error" && window.location.pathname === "/auth") {
          navigate('/self-pace-course')
          toast.error(res.data.message);
        }

      }
      // setData(response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div className="Main-login-mobile">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} id="loginForm">
        <div className="form-filed-group position-relative">
          <label>Email</label>
          <input
            type="email"
            className="Person-text1"
            placeholder="Enter your email"
            name="text"
            defaultValue={localStorage.getItem("email") || ""}
            {...register("text")}
          />
          <img className="person" src={email} alt="#" />
          {errors.text && (
            <p className="text-danger font-italic mt-2">{errors.text?.message}</p>
          )}
        </div>
        <div className="form-filed-group position-relative">
          <label>Password</label>
          {inputType === "password" ? (
            <img
              className="person"
              src={eyeclosedpassword}
              alt="#"
              onClick={() => setInputType("text")}
            />
          ) : (
            <img
              className="person"
              src={eyepassword}
              alt="#"
              onClick={() => setInputType("password")}
            />
          )}
          <input
            type={inputType}
            className="Person-text1"
            placeholder="Enter your password"
            name="password"
            defaultValue={localStorage.getItem("password") || ""}
            {...register("password")}
          />
          {errors.password && (
            <p className="text-danger font-italic mt-2">{errors.password?.message}</p>
          )}
        </div>
        <div className="signup-forgetpassword" style={{ textAlign: "right" }}>
          <u
            onClick={() => navigate("/auth/forgot-password")}
            style={{ cursor: "pointer" }}
          >
            Forgot Password?
          </u>
        </div>
        <div className="signup-captcha">
          {CONFIG?.CAPTCHA_SITE_KEY && (
            <>
              <ReCAPTCHA
                name="CAPTCHA"
                sitekey="6Lf7TMooAAAAAEso0lagXFxneOGyJshTzBsBzeYv"
                onChange={onHandleCaptcha}
                onExpired={handleCaptchaExpired}
              />
              {(captchaVerified == false && captchaMsg) && (
                <p className="text-danger font-italic mt-2">{captchaMsg}</p>
              )}
            </>
          )}
          <div className="signup-termpolicy mb-4 align-items-center">
            <Checkbox
              id="rememberMe"
              name="rememberMe"
              checked={rememberMe}
              onChange={(e) => handleRemember(e)}
            />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
        </div>
        <Button
          className="btn-ls"
          variant="text"
          sx={{
            mb: 4,
            background: "#d3f433",
            borderRadius: 6,
            width: 165,
            ml: 2,
            height: 55,
            ":hover": { background: "#d3f433" },
            color: "black",
          }}
          type="submit"
          loading={`${loading}`}
        // disabled={loading}
        >
          Login
        </Button>
        {/* <div className="login-footer mt-4">
          <div>
            <p className="login-footer-text">Or Login with</p>
          </div>
          <div className="login-socialmedia ">
            <div style={{ display: "flex" }}>
              <LoginSocialFacebook
                appId="3789506587943364"
                appSecret="fe2ea05ed11799935394b436a300f463"
                redirect_uri="/auth"
                onLoginStart={onLoginStart}
                onResolve={onLoginSuccess}
                className="socialClick"
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <img src={facebookIcon} alt="#" />
              </LoginSocialFacebook>
              <LoginSocialLinkedin
                client_id={"77fajbz1jz7jod" || ""}
                client_secret={"KEAKg60YAFdeJh2B" || ""}
                redirect_uri={REDIRECT_URI}
                onLoginStart={onLoginStart}
                scope="r_liteprofile,r_emailaddress"
                onResolve={({ provider, data }) => {
                  setProvider(provider);
                  setProfile(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <img src={linkedIn} alt="#" />
              </LoginSocialLinkedin>
              <LoginSocialTwitter
                isOnlyGetToken
                client_id={""}
                redirect_uri={"/"}
                className="socialClick"
                onLoginStart={onLoginStart}
                onResolve={({ provider, data }) => {
                  setProvider(provider);
                  setProfile(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <img src={twitter} alt="#" />
              </LoginSocialTwitter>
            </div>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default Login;
