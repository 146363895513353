import { usePost, useFetch } from "../utils/react-query";

import { pathToUrl } from "../utils/router";

import { apiRoutes } from "./constant";


export const useDownloadIntroAttachment = (updater) => {
    const response = usePost(
      pathToUrl(apiRoutes.donnloadIntro),
      undefined,
      updater
    );
    return {
      ...response,
    };
  };

  export const useShareBadge = (updater) => {
    const response = usePost(
      pathToUrl(apiRoutes.shareBadge),
      undefined,
      updater
    );
    return {
      ...response,
    };
  };

  export const useDownloadCertificate = (updater) => {
    const response = usePost(
      pathToUrl(apiRoutes.getCertificate),
      undefined,
      updater
    );
    return {
      ...response,
    };
  };


  

  export const useGetCourseDetail = (params) => {
    const res= useFetch(apiRoutes.courseDetail, params, {
    });
  
    return {
      ...res,
      data:res?.data?.data??{}
    };
  
  };

  export const useUploadAssignment = () => {
    const res= usePost(apiRoutes.uploadAssignment,undefined, undefined,{
      headers: {
        "Content-Type": "multipart/form-data",
    },
    });
  
    return {
      ...res,
      data:res?.data?.data??{}
    };
  
  };


  

  export const useGetCourseList = () => {
    const response = useFetch(pathToUrl(apiRoutes.myCourse));
  
    return {
      ...response,
      data: response?.data,
    };
  };