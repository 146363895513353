import { createContext,useReducer } from 'react';
import { useShowCart } from '../api/auth';
import { useEffect } from 'react';
import { cartReducer, initialState } from "./useReducer";

export const CartContext = createContext();

const  CartProvider=(({children})=>{
    const { isLoading, data, refetch } = useShowCart();

    const cartId=data?.data?.[0]?.cart_id
   

    const state={
        isLoading,
        cartId,
        data:data?.data??[],
        totalPrice:data?.totalPrice ?? 0,
        refetch
    }
   

    return  <CartContext.Provider value={state}>
        {children}
 </CartContext.Provider>
})
export   {CartProvider}