import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { ColorButton } from "./Login";
import { toast } from "react-toastify";
import { useForgotPassword } from "../../api/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import email from "../../assets/noun-mail-1707180.svg";
import CarouselLeft from "../CarouselLeft";
import forgotImage from "../../assets/Ski Crash.jpg"
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const validationSchema = z.object({
    email: z.string().min(1, { message: "Email is required" }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(validationSchema) });

  const { mutateAsync } = useForgotPassword();

  const forgotPassword = () => {
    setReset(true)
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    const payload = {
      email: formData?.email,
    };
    try {
      const res = await mutateAsync(payload);
      if (res?.data?.status === "success") {
        localStorage.setItem('email',formData?.email)
        localStorage.setItem('user_id',res?.data?.user_id)
        
        toast.success(res?.data?.message);
        navigate("/auth/password-reset", {
          
          // state: { email: formData?.email, id: res?.data?.data?.user_id },
        });
      } else if (res?.data?.status === "error") {
        setLoading(false);
        localStorage.setItem('email',formData?.email)
        toast.error(res?.data?.message);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message);
    }
  };
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row">
          <CarouselLeft image={forgotImage} />
          <div className="col-md-6 login-signup-wrapp forgot-password-wrap form-wrap d-flex align-items-center justify-content-center">
            { reset ?
            <div className="psword-reset">
              <div className="form-title"><span>Password Reset</span></div>
              <div className="d-flex green-tick-block">
                  <div className="green-tick-img-block">
                    <img src="../img/green-tick-mark.png" alt="" />
                  </div>
                  <div>
                  A password reset message was sent to your email address. please click the link in that message to reset your password.
                  </div>
              </div>
              <div className="d-flex mt--4">
                  <a href="#" className="bt me-4">Resend</a>
                  <span className="resend-counter">54 seconds</span>
              </div>
            </div>
            :
            <div className="Main-login-mobile">
            <form className="w-100" autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
             >
           <div className="form-title"><span>Forgot Password</span></div>
          
           <div className="form-filed-group position-relative">
                <label>Email</label>
                <input type="text" name="email"  className="Person-text1"
              {...register("email")} placeholder="Enter you registered Email id" required />
                <img className="person top-12" src={email} alt="#" />

                {errors.text && (
              <p className="text-danger font-italic mt-2">
                {errors.text?.message}
              </p>
            )}
            </div>

            <div className="btn-group mt--4">
              <button className="btn-v" key="submit" type="submit">Submit</button>
            </div>
        </form>
        </div>
             }
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
