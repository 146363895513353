import React from "react";
import Carousel from "react-bootstrap/Carousel";
import loginsignup1 from "../assets/login-signup-1.jpg";
import whitelogologin from "../assets/logo-white-login.svg";
const CarouselLeft = ({image,message}) => {
  return (
    <div className="col-md-6 login-slider left-slide position-relative color-for-background">
      <Carousel controls={false}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-100 "
            src={image}
            alt="First slide"
          />

          {/* <Carousel.Caption>
            <h1>Welcome!</h1>

            <p>Enter your details and start journey with us</p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 h-100"
            src={image}
            alt="Second slide"
          />

          {/* <Carousel.Caption>
            <h3>Second slide label</h3>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100 h-100"
            src={image}
            alt="Third slide"
          />

          {/* <Carousel.Caption>
            <h3>Third slide labelc</h3>

            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>

      <div className="slider-cnt">
        <img className="login-logo-white" src={whitelogologin} alt="#" />

        <p className="slider-cnt-heading">Welcome!</p>

        <p className="slider-cnt-Subheading">
          
          {message || "Journey out on a new path by creating one."}
        </p>

        {/* <ol className="carousel-indicators">

                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active slider-nav"></li>

                            <li data-target="#carouselExampleIndicators" data-slide-to="1" className="slider-nav"></li>

                            <li data-target="#carouselExampleIndicators" data-slide-to="2" className="slider-nav"></li>

                        </ol> */}
      </div>
    </div>
  );
};

export default CarouselLeft;
