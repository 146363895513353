import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import loginsignup1 from "../../assets/login-signup-1.jpg";

import verify2 from '../../assets/verify-2.jpg';

import loginsignup3 from "../../assets/login-signup-3.jpg"

import Carousel from "react-bootstrap/Carousel";

import PropTypes from "prop-types";

import whitelogologin from "../../assets/logo-white-login.svg";
import { Box, Tab, Tabs } from "@mui/material";
import Login from "./Login";
import Signup from "./signUp";
import { useEffect } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Auth = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location=useLocation()
  useEffect(()=>{
    if(location?.state){
      setValue(1)
    }
  },[])
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row height-100vh-ipad">
          <div className="col-md-6 login-slider position-relative color-for-background">
            <Carousel controls={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100 h-100 "
                  src={loginsignup1}
                  alt="First slide"
                />

                {/* <Carousel.Caption>
                  <h1>Welcome!</h1>

                  <p>Enter your details and start journey with us</p>
                </Carousel.Caption> */}
              </Carousel.Item>

              <Carousel.Item>
                <img 
                  className="d-block w-100 h-100"
                  src={loginsignup3}
                  alt="Second slide"
                />

                {/* <Carousel.Caption>
                  <h3>Second slide label</h3>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption> */}
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100 h-100"
                  src={loginsignup3}
                  alt="Third slide"
                />

                {/* <Carousel.Caption>
                  <h3>Third slide labelc</h3>

                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>

            <div className="slider-cnt">
              <img className="login-logo-white" src={whitelogologin} alt="#" />

              <p className="slider-cnt-heading">Welcome!</p>

              <p className="slider-cnt-Subheading">
                Enter your details and start journey with us
              </p>

              {/* <ol className="carousel-indicators">

                            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active slider-nav"></li>

                            <li data-target="#carouselExampleIndicators" data-slide-to="1" className="slider-nav"></li>

                            <li data-target="#carouselExampleIndicators" data-slide-to="2" className="slider-nav"></li>

                        </ol> */}
            </div>
          </div>

          <div className="col-md-6 login-signup-wrapp">
            <div className="ls-tabs">
              {/* <div className="login-heading  gap-bottom-sm">
                <div>
                  <p>Login</p>
                </div>

                <div>
                  <p className="login-signuphead">Signup</p>
                </div>
              </div> */}
              <Box className="lt-block" sx={{ display: "flex" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab className="login-button-mobile"
                    label="Login"
                    {...a11yProps(0)}
                    sx={{ fontSize: "42px" }}
                  />
                  <Tab
                    label="Sign Up"
                    {...a11yProps(1)}
                    sx={{ fontSize: "42px" }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Login />
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className="signup-forgetpassword mt-4 font-underline"
                  onClick={(e) => handleChange(e, 1)}
                >
                  <u>Create a new account</u>
                  
                </div>
                {/* <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className="signup-forgetpassword mt-4 font-underline"
                  onClick={(e) => handleChange(e, 1)}
                >
                  <u>Back To Home</u>
                  
                </div> */}
              </TabPanel>
              
              <TabPanel value={value} index={1}>
                <Signup />
                <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className="signup-forgetpassword mt-4 font-underline"
                  onClick={(e) => handleChange(e, 0)}
                >
                  <u>Already Registered?, Log in</u>
                </div>
                {/* <div
                  style={{ textAlign: "center", cursor: "pointer" }}
                  className="signup-forgetpassword mt-4 font-underline"
                  onClick={(e) => handleChange(e, 0)}
                >
                  <u>Back To Home</u>
                </div> */}
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auth;
