import usericon from "../../assets/user-svgrepo-com.svg";
import email from "../../assets/noun-mail-1707180.svg";
import phoneicon from "../../assets/noun-call-5054088.svg";
import eyepassword from "../../assets/eye-open-svgrepo-com.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ReCAPTCHA from 'react-google-recaptcha';
import eyeclosedpassword from "../../assets/eye-closed.png";
import { z } from "zod";
// import { ColorButton } from "./Login";
import { useRegisterUser } from "../../api/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import { CONFIG } from '../../config';
import {  Button } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function Signup() {
  const [inputType, setInputType] = useState("password");
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [phone, setPhone] = useState('');
  const [captchaMsg, setCaptchMsg] = useState(null)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validationSchema = z.object({
    fullName: z.string().min(1, { message: "Full Name is required" }),

    email: z.string().min(1, { message: "Email is required" }).email({
      message: "Must be a valid email",
    }),
    
    password: z
      .string()
      .min(8, { message: "Password must be atleast 8 characters" }),
    terms: z.literal(true, {
      errorMap: () => ({ message: "You must accept Terms and Conditions" }),
    }),
  });

  console.log(z)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(validationSchema) });

  const { mutateAsync } = useRegisterUser();

  function onHandleCaptcha(value) {
    if (value) {
      setCaptchaVerified(true);
    }
  }

  const handleCaptchaExpired = () => {
    setCaptchaVerified(false);
  };

  const onSubmit = async (formData) => {
 
    setLoading(true);
    const payload = {
      full_name: formData?.fullName,
      email: formData?.email,
      phone_no: phone,
      password: formData?.password,
      terms_conditions: true,
    };
    try {
      if(captchaVerified){
      const res = await mutateAsync(payload);
      console.log(res?.data?.status)
      if (res?.data?.status == "success") {
        toast.success(res?.data?.message);
        navigate("/verify-email", {
          state: { email: formData?.email, id: res?.data?.data?.user_id },
        });
      } else if (res?.data?.status == "error") {
        console.log(res?.data?.message[0])
        if(res?.data?.message[0] == "T"){
          toast.error(res?.data?.message); 
       
        } else {
          toast.error(res?.data?.message[0]);
        }
        setLoading(false);
      }
    } else {
      setCaptchMsg("Captcha is Required !")
    }
    } catch (e) {
      toast.error(e);
      setLoading(false);
    }
  };
 
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="form-filed-group position-relative">
          <label>Full Name</label>
          <input
            type="text"
            className="Person-text1"
            placeholder="Enter your full name"
            id="fullName"
            name="fullName"
            {...register("fullName")}


          />
          <img className="person" src={usericon} alt="#" />
          {errors.fullName && (
            <p className="text-danger font-italic mt-2">
              {errors.fullName?.message}
            </p>
          )}
        </div>

        <div className="form-filed-group position-relative">
          <label>Email</label>
          <input
            type="email"
            className="Person-text1"
            placeholder="Enter your email"
            id="email"
            name="email"
            {...register("email")}


          />
          <img className="person" src={email} alt="#" />
          {errors.email && (
            <p className="text-danger font-italic mt-2">
              {errors.email?.message}
            </p>
          )}
        </div>

        

        <div className="form-filed-group position-relative">
          <label>Phone Number</label>

          {/* <input
            type="text"
            className="Person-text1"
            placeholder="+91"
            id="phone"
            name="phone"
            {...register("phone")}


          /> */}
          <PhoneInput
                type="text"
                country='in'
                className="Person-text1"
                id="phone"
                name="phone"
                enableSearch
                onChange={phone => setPhone(phone)}
              
             
                
              />
          <img className="person" src={phoneicon} alt="#" />
          {errors.phone && (
            <p className="text-danger font-italic mt-2">
              {errors.phone?.message}
            </p>
          )}
        </div>

        <div className="form-filed-group position-relative">
          <label>Create Password</label>
          {inputType === "password" ? (
            <img
              className="person"
              src={eyeclosedpassword}
              alt="#"
              onClick={() => setInputType("text")}
            />
          ) : (
            <img
              className="person"
              src={eyepassword}
              alt="#"
              onClick={() => setInputType("password")}
            />
          )}
          <input
            type={inputType}
            className="Person-text1"
            placeholder="Type your password"
            id="password"
            name="password"
            {...register("password")}
          />
          {errors.password && (
            <p className="text-danger font-italic mt-2">
              {errors.password?.message}
            </p>
          )}
        </div>


        

        
        

        

        <div className="signup-captcha">
          <div
            className="g-recaptcha"
            data-sitekey="6Lf7TMooAAAAAEso0lagXFxneOGyJshTzBsBzeYv"
          >
       
          <ReCAPTCHA
          sitekey="6Lf7TMooAAAAAEso0lagXFxneOGyJshTzBsBzeYv"
          onChange={onHandleCaptcha}
          onExpired={handleCaptchaExpired}
        />
               {(captchaVerified == false && captchaMsg) && (
                <p className="text-danger font-italic mt-2">{captchaMsg}</p>
              )}

          </div>

          <div className="signup-termpolicy mb-4">
            <Checkbox
              // id="vehicle1"
              // name="vehicle1"
              // value="Bike"
              id="terms"
              name="terms"
              {...register("terms")}
            />

            <label htmlFor="terms"> <Link to="/policy" target="_blank" >I agree to the Terms and Policy</Link></label>
            {errors.terms && (
              <p className="text-danger mt-2">{errors.terms?.message}</p>
            )}
          </div>
        </div>

        <div className="helpsection-container">
          <Button className="btn-ls" variant="text"  sx={{height:50,background:'#feff07c9', borderRadius:6,width:165, ":hover": {backgroundColor: "#feff07c9" }, color:'black'  }}
          type="submit" loading={`${loading}`} >
            Sign Up
          </Button>
          
        </div>
        {/* <div className="login-footer mt-4">
          <div>
            <p className="login-footer-text">Or Login with</p>
          </div>
          </div> */}
      </form>
    </>
  );
}
