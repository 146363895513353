import React from "react";
import Carousel from "react-bootstrap/Carousel";
import loginsignup1 from "../assets/login-signup-1.jpg";
import whitelogologin from "../assets/logo-white-login.svg";
const BadgeLeft = ({image,message}) => {
  return (
    <div className="col-md-6 login-slider left-slide position-relative">
      <Carousel controls={false} slide={false}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-100 "
            src={image}
            alt="First slide"
          />
        </Carousel.Item>

      </Carousel>

    </div>
  );
};

export default BadgeLeft;
