import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import { ColorButton } from "./Login";
import { toast } from "react-toastify";
import { useForgotPassword } from "../../api/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import email from "../../assets/noun-mail-1707180.svg";
import CarouselLeft from "../CarouselLeft";
import successImage from "../../assets/Flying Ski.jpg"
const ReeeetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validationSchema = z.object({
    email: z.string().min(1, { message: "Email is required" }),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(validationSchema) });

  const { mutateAsync } = useForgotPassword();

  const onSubmit = async (formData) => {
    setLoading(true);
    const payload = {
      email: formData?.email,
    };
    try {
      const res = await mutateAsync(payload);
      if (res?.data?.status === "success") {
        toast.success(res?.data?.message);
        navigate("/auth/password-reset", {
          // state: { email: formData?.email, id: res?.data?.data?.user_id },
        });
      } else if (res?.data?.status === "error") {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.message);
    }
  };
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row">
          <CarouselLeft  image={successImage}  />
          <div className="col-md-6 login-signup-wrapp forgot-password-wrap form-wrap d-flex align-items-center justify-content-center">
          <div className="fgt-password">
          <form className="w-100" autoComplete="off">
            <div className="form-title"><span>Reset Password</span></div>
            <div className="form-filed-group position-relative mb-4">
                <label>New Password</label>
                <input type="text" placeholder="Your password" />
                <img className="person" src='../img/eye-icon.png' alt="#" />
            </div>
            <div className="form-filed-group position-relative">
                <label>Confirm Password</label>
                <input type="text" placeholder="Confirm your password" />
                <img className="person" src='../img/eye-icon.png' alt="#" />
            </div>
            <div className="btn-group mt--4">
              <button className="btn-v">Save</button>
            </div>
          </form>
        
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReeeetPassword;
