import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import loginsignup1 from "../../assets/login-signup-1.jpg";
import whitelogologin from "../../assets/logo-white-login.svg";
import { useLocation } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import { ColorButton } from "./Login";
import { toast } from "react-toastify";
import { useForgotPassword } from "../../api/auth";
import tick from "../../assets/tick.png";
import CarouselLeft from "../CarouselLeft";
import resetImage from "../../assets/Ski Crash.jpg"

const PasswordReset = () => {
  const location = useLocation();
  const [counter, setCounter] = useState(60);
  const email = localStorage.getItem('email');
  const id = localStorage.getItem('user_id');
  const { mutateAsync } = useForgotPassword();
  console.log(email)
  const handleResend = async () => {
    setCounter(60);
    try {
      
      const res = await mutateAsync({
        email: email,
      });
      if (res?.data?.status === "success") {
        toast.success(res?.data?.message,{
          className: 'my-toast-message-reset', 
        });
      } else {
        toast.error(res?.data?.message);
      }
    } catch (e) {
      toast.error(e);
    }
  };
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  });
  return (
    <>
      <div className="container-fluid login-wrapp">
        <div className="row">
          <CarouselLeft image={resetImage} message="Journey out on a new path by creating one" />
          <div className="col-md-6 login-signup-wrapp d-flex align-items-center justify-content-center">
            {/* <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: "16px",
                width: "50%",
              }}
            >
              <Typography sx={{ fontSize: "48px", fontWeight: "bold" }}>
                Password Reset
              </Typography>
              <Divider
                sx={{ background: "#565C83", width: "166px", height: "4px" }}
              />
              <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                <img src={tick} alt="" height={48} width={48} />
                <Typography>
                  A password reset message was sent to your email address.
                  please click the link in that message to reset your password.
                </Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                className="mt-2"
              >
                <Button
                  type="text"
                  className="signup-forgetpassword "
                  style={{ cursor: "pointer" }}
                  onClick={handleResend}
                  disabled={counter > 0}
                >
                  <u>Resend</u>
                </Button>
                <Typography sx={{ color: "grey" }}>
                  {counter} seconds
                </Typography>
              </div>
            </div> */}
            <div className="psword-reset">
              <div className="form-title"><span>Password Reset</span></div>
              <div className="d-flex green-tick-block">
                  <div className="green-tick-img-block">
                    <img src="../img/green-tick-mark.png" alt="" />
                  </div>
                  <div>
                  A password reset message was sent to your email address. please click the link in that message to reset your password.
                  </div>
              </div>
              <div className="d-flex mt--4">
              <button className="bt me-4 simple-link" type="button" onClick={handleResend} disabled={counter > 0}>Resend</button>
                <span className="resend-counter"> {counter} seconds</span>
              {/* {counter > 0 ?
                <span className="resend-counter"> {counter} seconds</span>
                :

                <button className="bt me-4 simple-link" type="button" onClick={handleResend} disabled={counter > 0}>Resend</button>
              } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
