import React, { useState } from "react";

import { useLocation, useNavigate,useParams } from "react-router-dom";

import { Divider, Typography } from "@mui/material";
import { ColorButton } from "./Login";
import { toast } from "react-toastify";
import { useForgotPassword } from "../../api/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGetCourseDetail } from "../../api/courses";
import { useForm } from "react-hook-form";
import { z } from "zod";
import email from "../../assets/noun-mail-1707180.svg";
import { Helmet } from "react-helmet";  
import BadgeLeft from "../BadgelLeft";
import successImage from "../../assets/Flying Ski.jpg"
const BagdeShare = () => {
 
  const navigate = useNavigate();
  const {id}=useParams()
  const url = "/course/"

  const ss = (e) =>{
   
    try{
    const link = document.createElement('a');
    link.href = url + courseDetail?.id;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }catch(e){
     
    
  }
   
  }

 
  const {isLoading:isFetchingCourse, data:courseDetail,refetch:fetchdatacourse } = useGetCourseDetail({ course_id: id });

  return (
    <>

<Helmet>  

                        <title>{courseDetail?.name} - Badge </title>  

                        <meta name="description" content={courseDetail?.assignment_description} />  
                        <meta name="image" content={courseDetail?.badge_image} />  
                        <meta name="theme-color" content="#E6E6FA" />  
                        <meta property="og:title" content="IRIS Course Badge" />
                        <meta property="og:description" content={courseDetail?.assignment_description}  />
                        <meta property="og:type" content="article"  />
                        <meta property="og:url" content={url + courseDetail?.id}  />
                        <meta property="og:image" content={courseDetail?.badge_image} />
                        <meta property="og:image:type" content="image/jpeg"  />
                        <meta property="og:image:width" content="300"  />
                        <meta property="og:image:height" content="300"  />
                                               </Helmet>  


      <div className="container-fluid login-wrapp">
        <div className="row">

            
          <BadgeLeft  image={courseDetail?.badge_image}  />
          <div className="col-md-6 login-signup-wrapp forgot-password-wrap form-wrap d-flex align-items-center justify-content-center">
          <div className="fgt-password">
<p>
Just completed the amazing {courseDetail?.name} online self-paced course from IRIS Training & Consulting and received an amazing badge. Click on the button below to browse through the course.

</p>
<p><button className="btn-v" onClick={ss}> Discover the Course</button></p>
    
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BagdeShare;
